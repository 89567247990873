<template>
  <div class="refund-booking-page">
    <BackTitle @click="goBack">Issue refund</BackTitle>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <div v-else class="refund-box">
      <RefundBookingForm
        v-model="refundAmount"
        :checkout-info="checkoutInfo"
        :refund-amount-full="refundAmountFull"
      />
    </div>
    <div class="mt-auto">
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="isInvalid"
        :isLoading="isSubmitting"
        @click="refund"
      >
        Refund ${{
          refundAmount === null ? refundAmountFull : refundAmount || 0
        }}
      </Button>
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import dialog from "@/plugins/dialog";
import BookingService from "@/api/services/BookingService";
import RefundBookingForm from "@/components/bookings/refund/RefundBookingForm";
import { alertRefundResult, getBookingPrice } from "@/helpers/utils";

export default {
  name: "RefundBookingPage",
  components: { RefundBookingForm, BackTitle },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      refundAmount: null,
    };
  },
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
      selectedProduct: (state) => state.products.selectedProduct,
    }),
    checkoutInfo() {
      if (!this.booking?.checkoutInfo) return {};

      return getBookingPrice(this.booking.checkoutInfo);
    },
    refundAmountFull() {
      return this.checkoutInfo.availableForRefund / 100;
    },
    isInvalid() {
      return !(
        this.refundAmount === null ||
        (this.refundAmount > 0 && this.refundAmount < this.refundAmountFull)
      );
    },
  },
  async created() {
    if (!this.booking) {
      try {
        this.isLoading = true;
        await this.fetchBookingById(this.$route.params.id);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchBookingById: "bookings/fetchBookingById",
    }),
    async refund() {
      const amountConfirm = this.refundAmount || this.refundAmountFull;
      const confirmed = await dialog.confirm({
        title: `Refund $${amountConfirm}?`,
        okText: "Refund",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isSubmitting = true;
          const amount = this.refundAmount
            ? Math.round(this.refundAmount * 100)
            : undefined;
          const refundResult = await BookingService.refundBooking({
            bookingId: this.$route.params.id,
            amount,
          });
          if (refundResult) {
            await alertRefundResult(refundResult);
            this.goBack();
          }
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    goBack() {
      this.$router.push({
        name: "BookingDetail",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-booking-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  .refund-box {
    background: $white;
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 24px 16px;

    @media (min-width: $media-laptop) {
      max-width: 550px;
    }
  }
}
</style>
